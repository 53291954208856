/* eslint-disable */
export const getMenuData = [
  {
    category: true,
    title: "menu.generale",
    key: "home",
    accessGroup: "home",
    access: ["home", "all"],
  },
  {
    title: "menu.statistique", //acceuil
    key: "home",
    accessGroup: "home",

    icon: "fa fa-pie-chart",
    access: ["statstiqueGeneral", "usersStats", "bulletin", "home", "all"],

    children: [
      {
        title: "menu.generale",
        key: "generale-statistique",
        accessGroup: "home",
        url: "/accueil",
        icon: "fa fa-home",
        access: ["all", "statstiqueGeneral"],
        buildingAccess: "statstiqueGeneral",
      },
      {
        title: "bulletin.bulletinV1",
        key: "bulletinStatistique",
        accessGroup: "bulletin",
        version: "v1",
        icon: "fa fa-file-text",
        url: "/bulletinStatistique",
        access: ["all", "statistique"],
        buildingAccess: "bulletin",
      },

      {
        title: "menu.bulletin",
        key: "bulletinStatistiqueV2",
        accessGroup: "bulletin",
        version: "v2",
        icon: "fa fa-file-text",
        url: "/bulletinStatistiqueV2",
        access: ["all", "statistique"],
        buildingAccess: "bulletin",
      },
      {
        title: "menu.connection",
        key: "connection-statistique",
        accessGroup: "home",
        url: "/connected",
        icon: "fa fa-user",
        access: ["all", "usersStats"],
        buildingAccess: "usersStats",
      },
      /*
      {
        title: "menu.powerbi",
        key: "powerbi",
        accessGroup: "home",
        url: "/powerbi",
        icon: "fa fa-stat",
        access: ["all", "home"],
        buildingAccess: "statstiqueGeneral",
      },
*/
    ],
  },

  {
    category: true,
    title: "menu.parametrage",
    key: "parametrage",
    accessGroup: "parametrage",

    accessGroup: "parametrage",
    access: ["all", "etablissement", "comptabilite", "scolarite"],
  },

  {
    title: "menu.parametrage",
    key: "parametrage",
    accessGroup: "parametrage",
    icon: "fa fa-cogs",
    access: [
      "all",
      "etablissement",
      "comptabilite",
      "scolarite",
      "smart_schedule",
    ],
    children: [
      {
        title: "menu.etablissement",
        key: "etablissement",
        accessGroup: "parametrage",
        url: "/etablissement",
        icon: "fa fa-university",
        access: ["all", "etablissement"],
        buildingAccess: "parametrageEtablissement",
      },
      {
        title: "menu.comptabilite",
        key: "comptabilite",
        accessGroup: "parametrage",

        url: "/comptabilite",
        icon: "fa fa-sort-numeric-desc",
        access: ["all", "comptabilite"],
        buildingAccess: "parametrageComptabilite",
      },
      {
        title: "menu.scolarite",
        key: "scolarite",
        accessGroup: "parametrage",

        url: "/scolarite",
        icon: "fa fa-pencil",
        access: ["all", "scolarite"],
        buildingAccess: "parametrageScolarite",
      },
      {
        title: "menu.bulletin",
        key: "bulletin",
        accessGroup: "parametrage",

        url: "/bulletin",
        icon: "fa fa-file-text",
        access: ["all", "bulletin"],
        buildingAccess: "bulletin",
      },
      {
        title: "menu.sms",
        key: "smsp",
        accessGroup: "parametrage",

        url: "/sms-settings",
        icon: "fa fa-envelope",
        access: ["all", "sms"],
        buildingAccess: "sms",
      },
      {
        title: "menu.smartSchedule",
        key: "smartScheduleSettings",
        url: "/smartscheduleParams",
        icon: "fa fa-clock-o",
        accessGroup: "parametrage",
        access: ["all", "smart_schedule"],
        buildingAccess: "smartSchedule",
      },
    ],
  },
  {
    title: "menu.administration",
    key: "adminstration",
    accessGroup: "adminstration",

    icon: "fa fa-expeditedssl",
    access: ["all", "autorisation", "utilisateur"],
    children: [
      {
        title: "menu.autorisation",
        key: "autorisation",
        accessGroup: "adminstration",

        url: "/autorisation",
        icon: "fa fa-low-vision",
        access: ["all", "autorisation"],
      },
      {
        title: "menu.utilisateur",
        key: "utilisateur",
        accessGroup: "adminstration",

        url: "/utilisateur",
        icon: "fa fa-universal-access",
        access: ["all", "utilisateur"],
      },
    ],
  },
  {
    category: true,
    title: "menu.scolarite",
    key: "scolarite",
    accessGroup: "scolarite",

    access: ["all", "liste_eleves"],
  },
  {
    title: "menu.listeEleves",
    key: "liste_eleves",
    accessGroup: "liste_eleves",

    icon: "fa fa-database",
    url: "/elevesliste",
    access: ["all", "liste_eleves"],
    buildingAccess: "studentList",
  },

  {
    title: "menu.preinscription",
    key: "preinscription",
    accessGroup: "liste_eleves",

    icon: "fa fa-database",
    url: "/preinscription",
    access: ["liste_eleves"],
  },

  {
    title: "menu.activites",
    key: "activite",
    accessGroup: "activite",

    icon: "fa fa-tags",
    access: ["all", "inscription", "etat_paiement", "cantine"],
    buildingAccess: "activites",

    children: [
      {
        title: "menu.inscriActiv",
        key: "inscriptionActivites",
        accessGroup: "activite",

        url: "/inscriptionActivites",
        icon: "fa fa-mortar-board",
        access: ["all", "inscription"],
      },

      {
        title: "menu.etatPaiement",
        key: "etatPaiementActivite",
        accessGroup: "activite",

        url: "/etatPaiementActivite",
        icon: "fa fa-money",
        access: ["all", "etat_paiement"],
      },
      {
        title: "menu.cantine",
        key: "cantine",
        accessGroup: "activite",

        url: "/cantine",
        icon: "fa fa-cutlery",
        access: ["all", "cantine"],
        buildingAccess: "cantine",
      },
    ],
  },

  {
    title: "menu.scolarite",
    key: "scolarite",
    accessGroup: "scolarite",

    icon: "fa fa-graduation-cap",
    access: ["all", "inscription", "etat_paiement", "punition"],
    buildingAccess: "scolarite",

    children: [
      {
        title: "menu.inscriScol",
        key: "inscriptionScolarité",
        accessGroup: "scolarite",

        url: "/inscriptionScolarite",
        icon: "fa fa-book",
        access: ["all", "inscription"],
      },
      {
        title: "menu.etatPaiements",
        key: "etatDesImpayés",
        accessGroup: "scolarite",

        url: "/etatDesImpayes",
        icon: "fa fa-money",
        access: ["all", "etat_paiement"],
      },
      {
        title: "menu.listePunition",
        key: "listeDesPunitions",
        accessGroup: "scolarite",

        url: "/listePunitions",
        icon: "fa fa-legal",
        access: ["all", "punition"],
        buildingAccess: "punishments",
      },
    ],
  },
  {
    title: "menu.emploi",
    key: "emplois",
    accessGroup: "emplois",

    icon: "fa fa-calendar",
    access: ["all", "eleves", "enseignant", "smart_schedules", "teacher_dispo"],
    buildingAccess: "emplois",

    children: [
      {
        title: "emploi.classe",
        key: "schedule",
        accessGroup: "emplois",

        url: "/schedule",
        icon: "fa fa-calendar",
        access: ["all", "eleves"],
      },
      {
        title: "menu.enseignants",
        key: "teacherschedule",
        accessGroup: "emplois",

        url: "/teacherschedule",
        icon: "fa fa-briefcase",
        access: ["all", "enseignant"],
      },
      {
        title: "smartSchedule.smartSchedules",
        key: "ssmartSchedule",
        accessGroup: "emplois",
        url: "/smart-schedule",
        icon: "fa fa-calendar-check-o",
        access: ["all", "smart_schedules"],
        buildingAccess: "smartSchedule",
      },
      {
        title: "menu.teacherAvailability",
        key: "teacherAvailability",
        accessGroup: "emplois",
        url: "/teacherAvailability",
        icon: "fa fa-clock-o",
        access: ["all", "teacher_dispo"],
        buildingAccess: "teacherAvailability",
      },
    ],
  },
  {
    title: "menu.assiduite",
    key: "assiduite",

    accessGroup: "assiduite",
    icon: "fa fa-th-list",
    url: "/attendance",
    access: ["all", "assiduite"],
    buildingAccess: "attendance",
  },
  {
    title: "menu.personnel",
    key: "personnel",
    accessGroup: "personnel",

    url: "/listePersonnel",
    icon: "fa fa-users",
    access: [
      "all",
      "personnel",
      "enseignant",
      "etat_paiement",
      "viremenet_bancaire",
    ],
    buildingAccess: "personnel",

    children: [
      {
        title: "menu.listePersonnels",
        key: "listePersonnel",
        accessGroup: "personnel",

        url: "/listePersonnel",
        icon: "fa fa-suitcase",
        access: ["all", "personnel"],
      },
      {
        title: "menu.listeEnseignants",
        key: "listeEnseignant",
        accessGroup: "personnel",

        url: "/listeEnseignant",
        icon: "fa fa-briefcase",
        access: ["all", "enseignant"],
      },
      {
        title: "menu.etatPaiement",
        key: "etatDePaiement",
        accessGroup: "personnel",

        url: "/etatDePaiement",
        icon: "fa fa-book",
        access: ["all", "etat_paiement"],
      },
      {
        title: "menu.virementBancaire",
        key: "virementBancaire",
        accessGroup: "personnel",

        url: "/virementBancaire",
        icon: "fa fa-money",
        access: ["all", "viremenet_bancaire"],
      },
    ],
  },
  {
    category: true,
    title: "bulletin.bulletin",
    key: "bulletinCate",
    accessGroup: "bulletin",
    access: ["all", "pedagogic", "pilote", "specific"],
    buildingAccess: "bulletin",
    version: "v2",
  },
  {
    category: true,
    title: "bulletin.bulletinV1",
    key: "bulletinCate",
    accessGroup: "bulletin",
    access: ["all", "pedagogic", "pilote", "specific"],
    buildingAccess: "bulletin",
    version: "v1",
  },
  {
    title: "menu.accessTeacher",
    key: "accessTeacher",
    version: "v2",
    accessGroup: "personnel",
    icon: "fa fa-briefcase",
    url: "/teacherAccess",
    access: ["all", "enseignant"],
    buildingAccess: "personnel",
  },
  {
    title: "menu.bulletin",
    key: "bulletinv2",
    accessGroup: "bulletin",
    version: "v2",
    icon: "fa fa-file-text",
    access: ["all", "pedagogic"],
    buildingAccess: "bulletin",
    children: [
      {
        title: "bulletin.bulletinPedagogique",
        key: "bulletinPedagogiqueV2",
        buildingAccess: "bulletin",
        accessGroup: "bulletin",
        url: "/bulletinV2/bulletinPedagogique",
        icon: "fa fa-pencil",
        access: ["all", "pedagogic"],
      },
      {
        title: "bulletin.bulletinSpecifique",
        key: "bulletinSpecifiqueV2",
        buildingAccess: "bulletin",
        accessGroup: "bulletin",
        url: "/bulletinV2/bulletinPilote",
        icon: "fa fa-pencil",
        access: ["all", "pilote"],
      },
      {
        title: "bulletin.bulletinVerySpecifique",
        key: "bulletinVerySpecifiqueVV2",
        buildingAccess: "bulletin",
        accessGroup: "bulletin",
        url: "/bulletinV2/bulletinVerySpecifique",
        icon: "fa fa-pencil",
        access: ["all", "specific"],
      },
    ],
  },
  {
    title: "bulletin.bulletinPedagogique",
    key: "bulletin",
    accessGroup: "bulletin",
    version: "v1",
    count: "-",
    icon: "fa fa-file-text",
    access: ["all", "pedagogic"],
    buildingAccess: "bulletin",
    children: [
      {
        title: "menu.notesModule",
        key: "notesM",
        accessGroup: "bulletin",

        url: "/bulletin/notemodule",
        icon: "fa fa-pencil",
        access: ["all", "pedagogic"],
      },
      {
        title: "menu.notesModuleComplet",
        key: "notesC",
        accessGroup: "bulletin",

        url: "/bulletin/notemodulecomplet",
        icon: "fa fa-pencil",
        access: ["all", "pedagogic"],
      },
      {
        title: "menu.notesParEleve",
        key: "notesE",
        accessGroup: "bulletin",

        url: "/bulletin/noteeleve",
        icon: "fa fa-pencil",
        access: ["all", "pedagogic"],
      },
      {
        title: "appreciation.adminAppreciation",
        key: "APPadmin",
        accessGroup: "bulletin",

        url: "/bulletin/appreciationAdmin",
        icon: "fa fa-pencil",
        access: ["all", "pedagogic"],
      },
      {
        title: "menu.certificate",
        key: "notesCC",
        accessGroup: "bulletin",

        url: "/bulletin/certification",
        icon: "fa fa-pencil",
        access: ["all", "pedagogic"],
        buildingAccess: "certificate",
      },
    ],
  },
  {
    title: "bulletin.bulletinSpecifique",
    key: "bulletinSpec",
    accessGroup: "bulletin",
    version: "v1",
    icon: "fa fa-file-text",
    access: ["all", "pilote"],
    buildingAccess: "bulletin",
    children: [
      {
        title: "menu.notesModule",
        key: "specNotesM",
        accessGroup: "bulletin",

        url: "/specbulletin/notemodule",
        icon: "fa fa-pencil",
        access: ["all", "pilote"],
      },
      {
        title: "menu.notesModuleComplet",
        key: "specNotesC",
        accessGroup: "bulletin",

        url: "/specbulletin/notemodulecomplet",
        icon: "fa fa-pencil",
        access: ["all", "pilote"],
      },
      {
        title: "menu.notesParEleve",
        key: "specNotesE",
        accessGroup: "bulletin",

        url: "/specbulletin/noteeleve",
        icon: "fa fa-pencil",
        access: ["all", "pilote"],
      },
    ],
  },
  {
    title: "bulletin.bulletinVerySpecifique",
    key: "bulletinVerySpec",
    accessGroup: "bulletin",
    version: "v1",
    icon: "fa fa-file-text",
    access: ["all", "specific"],
    buildingAccess: "bulletin",
    children: [
      {
        title: "menu.notesModule",
        key: "specVNotesM",
        accessGroup: "bulletin",

        url: "/veryspecbulletin/notematiere",
        icon: "fa fa-pencil",
        access: ["all", "specific"],
      },
      {
        title: "menu.notesModuleComplet",
        key: "specVNotesC",
        accessGroup: "bulletin",

        url: "/veryspecbulletin/notemodule",
        icon: "fa fa-pencil",
        access: ["all", "specific"],
      },
      {
        title: "menu.notesParEleve",
        key: "specVNotesE",
        accessGroup: "bulletin",

        url: "/veryspecbulletin/noteeleve",
        icon: "fa fa-pencil",
        access: ["all", "specific"],
      },
    ],
  },

  {
    category: true,
    title: "menu.comptabilite",
    key: "comptabilite",
    accessGroup: "comptabilite",
    buildingAccess: "comptabilite",

    access: [
      "all",
      "etat_recettes",
      "journale_caisse",
      "depenses",
      "etat_impayes",
      "liste_reglement",
      "reglement_annule",
    ],
  },

  {
    title: "menu.comptabilite",
    key: "comptabilite",
    accessGroup: "comptabilite",

    icon: "fa fa-money",
    access: [
      "all",
      "etat_recettes",
      "avance",
      "journale_caisse",
      "depenses",
      "etat_impayes",
      "liste_reglement",
      "reglement_annule",
    ],
    buildingAccess: "comptabilite",
    children: [
      {
        title: "menu.etatRecettes",
        key: "etatDesRecettes",
        accessGroup: "comptabilite",

        url: "/etatDesRecettes",
        icon: "fa fa-list",
        access: ["all", "etat_recettes"],
      },
      {
        title: "menu.journaleCaisse",
        key: "journalCaisse",
        accessGroup: "comptabilite",

        url: "/journalCaisse",
        icon: "fa fa-book",
        access: ["all", "journale_caisse"],
      },
      {
        title: "menu.caisseAvance",
        key: "avance",
        accessGroup: "comptabilite",

        url: "/caisseavance",
        icon: "fa fa-money",
        access: ["all", "avance"],
      },

      {
        title: "menu.depenses",
        key: "depenses",
        accessGroup: "comptabilite",

        url: "/depenses",
        icon: "fa fa-money",
        access: ["all", "depenses"],
      },
      {
        title: "menu.etatImpayes",
        key: "etatDesImpayes",
        accessGroup: "comptabilite",

        url: "/etatDesImpayesC",
        icon: "fa fa-money",
        access: ["all", "etat_impayes"],
      },
      /*
      {
        title: "Ajoute recette",
        key: "ajouteRecette",
        url: "/ajouteRecette",
        icon: "fa fa-money",
      },
    */
      {
        title: "menu.listeReglement",
        key: "listeReglement",
        accessGroup: "comptabilite",

        url: "/listeReglement",
        icon: "fa fa-money",
        access: ["all", "liste_reglement"],
      },
      {
        title: "menu.reglementsAnnules",
        key: "listeReglementAnnulé",
        accessGroup: "comptabilite",

        url: "/listeReglementAnnule",
        icon: "fa fa-money",
        access: ["all", "reglement_annule"],
      },

      {
        title: "menu.cheques",
        key: "cheque",
        accessGroup: "comptabilite",

        url: "/cheques",
        icon: "fa fa-money",
        access: ["all", "etat_recettes"],
      },
    ],
  },
  {
    title: "menu.declaration",
    key: "declaration",
    accessGroup: "declaration",

    icon: "fa fa-eye",
    access: [
      "all",
      "etat_impayesA",
      "etat_impayesS",
      "declarationTVA",
      "chiffre_affaire",
      "chiffre_affaireS",
    ],
    buildingAccess: "declaration",

    children: [
      {
        title: "menu.etatImpayesActivites",
        key: "etatImpayesActivites",
        accessGroup: "declaration",

        url: "/etatImpayesActivites",
        icon: "fa fa-info",
        access: ["all", "etat_impayesA"],
      },
      {
        title: "menu.etatImpayesScolarite",
        key: "etatImpayesScolarite",
        accessGroup: "declaration",

        url: "/etatImpayesScolarite",
        icon: "fa fa-eye",
        access: ["all", "etat_impayesS"],
      },
      {
        title: "menu.declarationTva",
        key: "declarationTVA",
        accessGroup: "declaration",

        url: "/declarationTVA",
        icon: "fa fa-type",
        access: ["all", "declarationTVA"],
      },
      {
        title: "menu.chiffreAffaires",
        key: "chiffreAffaire",
        accessGroup: "declaration",

        url: "/chiffreAffaire",
        icon: "fa fa-trending-up",
        access: ["all", "chiffre_affaire"],
      },
      {
        title: "menu.chiffreAffairesScolarite",
        key: "chiffreAffaireScolarite",
        accessGroup: "declaration",

        url: "/chiffreAffaireScolarite",
        icon: "fa fa-activity",
        access: ["all", "chiffre_affaireS"],
      },
    ],
  },
  {
    category: true,
    title: "menu.actulite",
    key: "actualite",
    accessGroup: "actualite",
    access: ["actualite", "all"],
    buildingAccess: "news",
  },
  {
    title: "menu.actulite",
    key: "actualite",

    accessGroup: "actualite",
    icon: "fa fa-newspaper-o",
    url: "/actuality",
    access: ["all", "actualite"],
    buildingAccess: "news",
  },
  {
    category: true,
    title: "menu.calender",
    key: "calendar",
    accessGroup: "calendar",
    access: ["calendar", "all"],
    buildingAccess: "calender",
  },
  {
    title: "menu.calender",
    key: "calendar",
    accessGroup: "calendar",
    icon: "fa fa-calendar",
    url: "/calender",
    access: ["all", "calendar"],
    buildingAccess: "calender",
  },
  {
    category: true,
    title: "menu.sms",
    key: "sms",
    accessGroup: "sms",
    access: ["all", "sms"],
    buildingAccess: "sms",
  },
  {
    title: "menu.sms",
    key: "sms",

    accessGroup: "sms",
    icon: "fa fa-envelope",
    access: ["all", "sms", "history"],
    buildingAccess: "sms",

    children: [
      {
        title: "menu.envoyerSms",
        key: "send",
        accessGroup: "sms",

        url: "/sms/envoyer",
        icon: "fa fa-envelope-open",
        access: ["all", "send"],
      },
      {
        title: "menu.envoyerParClasseSms",
        key: "sendClass",
        accessGroup: "sms",

        url: "/sms/envoyerClasse",
        icon: "fa fa-envelope-open",
        access: ["all", "send"],
      },
      {
        title: "menu.envoyerSmsLogin",
        key: "sendLogin",
        accessGroup: "sms",

        url: "/sms/envoyerLogin",
        icon: "fa fa-lock",
        access: ["all", "send"],
      },
      {
        title: "menu.historiqueSms",
        key: "history",
        accessGroup: "sms",

        url: "/sms/historique",
        icon: "fa fa-inbox",
        access: ["all", "history"],
      },
    ],
  },

  {
    category: true,
    title: "menu.supervision",
    key: "supervision",
    accessGroup: "supervision",
    access: ["supervision", "all"],
    buildingAccess: "ptchat",
  },
  {
    title: "menu.ptchat",
    key: "ptchat",
    accessGroup: "supervision",
    icon: "fa fa-weixin",
    url: "/ptchat",
    access: ["all", "ptchat"],
    buildingAccess: "ptchat",
  },

  {
    category: true,
    title: "menu.degital_library",
    key: "degital_library",
    accessGroup: "digitalLibrary",
    access: ["all", "digitalLibrary"],
    buildingAccess: "digitalLibrary",
  },
  {
    title: "menu.degital_library_list",
    key: "degital_library_list",
    accessGroup: "digitalLibrary",
    icon: "fa fa-book",
    url: "/degital_library",
    access: ["all", "digitalLibrary"],
    buildingAccess: "digitalLibrary",
  },
  {
    category: true,
    title: "menu.marketPlace",
    key: "marketPlace",
    accessGroup: "ptchat",
    access: ["ptchat", "all"],
    buildingAccess: "ptchat",
  },
  {
    title: "menu.marketproducts_list",
    key: "marketproducts_list",
    accessGroup: "ptchat",
    icon: "fa fa-shopping-cart",
    url: "/marketPlace",
    access: ["all", "marketPlace"],
    buildingAccess: "ptchat",
  },
];
